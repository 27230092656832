import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import CustomHead from '@/components/CustomHead.vue';
import { systemModule } from '@/store/modules/system';
import { aboutModule } from '@/store/modules/about';
var VideoList = (function (_super) {
    __extends(VideoList, _super);
    function VideoList() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VideoList.prototype.mounted = function () {
        aboutModule.getVideoList();
    };
    Object.defineProperty(VideoList.prototype, "videoList", {
        get: function () {
            return aboutModule.videoList;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(VideoList.prototype, "logo", {
        get: function () {
            return systemModule.logo;
        },
        enumerable: false,
        configurable: true
    });
    VideoList = __decorate([
        Component({
            components: { CustomHead: CustomHead }
        })
    ], VideoList);
    return VideoList;
}(Vue));
export default VideoList;
